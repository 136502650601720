export default defineNuxtRouteMiddleware(async (to, from) => {
  if (process.client) {
    const { $stytch } = useNuxtApp()
    if ($stytch.session.getSync()) {
      try {
        await $stytch.session.authenticate({
          session_duration_minutes: useRuntimeConfig().public.SESSION_DURATION_MINUTES,
        })
      } catch (error) {
        clearNuxtData(apiPaths.user())
        return navigateTo('/logout')
      }
    } else {
      clearNuxtData(apiPaths.user())
      return navigateTo('/logout')
    }
  }

  const { user } = await useUser()

  if (!user.value) {
    return navigateTo('/logout')
  }

  const whitelistedPaywallRoutes = [
    'create-profile',
    'eligibility',
    'eligibility-id',
    'package',
    'acp-user-status',
    'acp-reset-users',
  ]

  const isPaywalledRoute = (): boolean => {
    return !whitelistedPaywallRoutes.includes(to.name as string)
  }

  // Redirect to package page if user is attempting to access a page behind the paywall
  if (isPaywalledRoute() && !user.value.paid && !to.query.paymentSuccess) {
    return navigateTo('/package')
  }
})
